<template >
    <div v-if="currentUser.superAdmin">
        <v-data-table
                :headers="headers"
                :items="users"
                :options.sync="pagination"
                :server-items-length="totalUsers"
                :loading="loading"
                :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
                class="elevation-1"
        >

            <template v-slot:item.activationDate="{ item }">
                {{ parseDate(item.activationDate) }}
            </template>

            <template v-slot:item.username="{ item }">
                <td class="text-xs-right">
                    {{
                    item.userProfiles != null && item.userProfiles.length > 0
                    ? item.userProfiles[0].fullName
                    : ""
                    }}
                </td>
            </template>
            <template v-slot:item.cityRoles="{ item }">
                {{ item.cityRoles.length > 0 ? item.cityRoles[0].name : "" }}
                <v-menu bottom offset-y>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="item.cityRoles.length > 1" small class="mr-2" v-on="on"
                        >mdi-dots-horizontal
                        </v-icon>
                    </template>
                    <v-list dense style="max-height: 500px" class="overflow-y-auto">
                        <v-list-item v-for="(item, i) in item.cityRoles" :key="i">
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:item.clear="{ item }">
                <v-avatar color="teal" size="26" rounded>
                    <router-link
                            :to="{
              name: 'AdminPanelUserEdit',
              params: { userId: item.id }
            }"
                    >
                        <v-icon dark>mdi-pencil</v-icon>
                    </router-link>
                </v-avatar>
            </template>
            <template v-slot:item.add="{ item }">
                <v-avatar color="red darken-4" size="26" rounded>
                    <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
                </v-avatar>
            </template>
            <template v-slot:body.prepend>
                <tr>
                    <td>
                        <v-text-field v-model="userId" type="text"
                                      @keyup.enter="page = 1;retrieveUsers();"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="userName" type="text"
                                      @keyup.enter="page = 1;retrieveUsers();"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="email" type="text"
                                      @keyup.enter="page = 1;retrieveUsers();"></v-text-field>
                    </td>
                    <td>
                        <v-spacer></v-spacer>
                        <!--            <v-datetime-picker-->
                        <!--              label="From"-->
                        <!--              v-model="dateFrom"-->
                        <!--              time-format="HH:mm:ss"-->
                        <!--          ></v-datetime-picker><v-datetime-picker-->
                        <!--              label="To"-->
                        <!--              v-model="dateTo"-->
                        <!--              time-format="HH:mm:ss"-->
                        <!--          ></v-datetime-picker>-->
                    </td>
                    <td>
                        <v-select
                                :items="superAdminList"
                                v-model="superAdmin"
                                dense
                                hide-details
                                @keyup.enter="page = 1;retrieveUsers();"
                        ></v-select>
                    </td>
                    <td>
                        <v-text-field v-model="cityRole" type="text"
                                      @keyup.enter="page = 1;retrieveUsers();"></v-text-field>
                    </td>
                    <td>
                        <v-avatar color="indigo" size="30">
                            <v-icon
                                    dark
                                    @click="
                      page = 1;
                      retrieveUsers();
                    "
                            >mdi-magnify
                            </v-icon>
                        </v-avatar>
                    </td>
                    <td>
                        <v-avatar color="grey" size="30">
                            <v-icon
                                    dark
                                    @click="
                      page = 1;
                      clearSearch();
                    "
                            >mdi-close
                            </v-icon>
                        </v-avatar>
                    </td>
                    <td>
                        <router-link :to="{ name: 'AdminPanelUserCreate' }">
                            <v-avatar color="teal" size="26" rounded>
                                <v-icon dark>mdi-plus</v-icon>
                            </v-avatar>
                        </router-link>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <ConfirmDialog ref="confirm"/>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import moment from "moment";
    import ConfirmDialog from "@/view/pages/ConfirmDialog";
    import {mapGetters} from "vuex";

    export default {
        name : "admin-panel-users" ,
        computed : {
            ...mapGetters(["currentUser"]),
            name(){return this.$i18n.t('menu.apUsers')},
            headers() {
              return  [
                    { text : "Id" , align : "start" , value : "id" , width : "5%" } ,
                    { text : this.$i18n.t('common.user') , value : "username" , width : "10%" , sortable : false } ,
                    { text : this.$i18n.t('common.email') , value : "email" , sortable : false , width : "10%" } ,
                    { text : this.$i18n.t('pages.apUsers.activationDate') , value : "activationDate" , width : "10%" } ,
                    { text : this.$i18n.t('pages.apUsers.superAdmin') , value : "roleName" , width : "10%" } ,
                    { text : this.$i18n.t('pages.apUsers.cityRoles') , value : "cityRoles" , width : "10%" , sortable : false } ,
                    { text : "" , value : "search" , width : "2%" , sortable : false } ,
                    { text : "" , value : "clear" , width : "2%" , sortable : false } ,
                    { text : "" , value : "add" , width : "2%" , sortable : false }
                ]
            }
        } ,
        data() {
            return {
                pageSizes : [10 , 20 , 50 , 100] ,
                userName : "" ,
                userId : "" ,
                email : "" ,
                cityRole : "" ,
                roleName : "" ,
                dateFrom : null ,
                dateTo : null ,
                dates : [] ,
                dateRangeMenu : false ,
                shopName : "" ,
                userRole : "" ,
                superAdmin : "" ,
                totalUsers : 0 ,
                users : [] ,
                loading : false ,
                pagination : {} ,
                dialogTest : false ,
                userProfile : null ,
                notifications : false ,
                sound : true ,
                widgets : false ,
                downloadingReport : false ,
                loader : null ,
                userRoleList : ["All" , "Member" , "Administrator" , "Superadmin" , "Partner"] ,
                superAdminList : ["All" , "No" , "Yes"] ,
                errors : []
            };
        } ,
        watch : {
            pagination : {
                handler() {
                    this.retrieveUsers ();
                } ,
                deep : true
            }
        } ,
        mounted() {
            this.$store.dispatch ( SET_BREADCRUMB , [{ title : "Admin Panel Users" }] );
        } ,
        components : {
            ConfirmDialog
        } ,
        methods : {
            parseDate(date) {
                if (date == null) return "";
                return moment
                    .utc ( date )
                    .local ()
                    .format ( "yyyy-MM-DD HH:mm:ss" );
            } ,
            async delRecord(item) {
                if (
                    await this.$refs.confirm.open (
                        "Confirm" ,
                        "Are you sure you want to delete admin panel user with id = " +
                        item.id +
                        " ?"
                    )
                ) {
                    this.deleteRecord ( item );
                }
            } ,
            deleteRecord(item) {
                this.deleteAdminPanelUser ( item.id );
            } ,
            deleteAdminPanelUser(id) {
                this.loading = true;
                ApiService.delete ( `api/ap-users/${id}` )
                    .then ( response => {
                        this.$log.debug ( "Admin panel user deleted: " , response );
                        this.retrieveUsers ();
                    } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => (this.loading = false) );
            } ,

            getRequestParams(
                userName ,
                email ,
                dateFrom ,
                dateTo ,
                cityRole ,
                userId ,
                superAdmin
            ) {
                let params = {};

                if (userName) {
                    params["username"] = userName;
                }

                if (email) {
                    params["email"] = email;
                }

                if (dateFrom) {
                    params["dateFrom"] = dateFrom;
                }

                if (dateTo) {
                    params["dateTo"] = dateTo;
                }

                if (cityRole) {
                    params["cityRole"] = cityRole;
                }

                if (userId) {
                    params["userId"] = userId;
                }

                if (superAdmin) {
                    params["superAdmin"] = superAdmin;
                }

                let sort;
                let sortBy = this.pagination.sortBy;
                let sortDesc = this.pagination.sortDesc;
                if (sortBy.length === 1 && sortDesc.length === 1) {
                    sort = sortBy[0];
                    if (sortDesc[0] === true) sort += ",desc";
                }
                params["sort"] = sort;
                params["page"] = this.pagination.page;
                params["size"] = this.pagination.itemsPerPage;

                return params;
            } ,
            clearSearch() {
                (this.userName = ""),
                    (this.email = ""),
                    (this.cityRole = ""),
                    (this.dateFrom = null),
                    (this.dateTo = null),
                    (this.superAdmin = ""),
                    this.userId = "";
            } ,
            retrieveUsers() {
                const params = this.getRequestParams (
                    this.userName ,
                    this.email ,
                    this.dateFrom ,
                    this.dateTo ,
                    this.cityRole ,
                    this.userId ,
                    this.superAdmin
                );

                return new Promise ( resolve => {
                    this.loading = true;
                    ApiService.query ( "api/ap-users" , {
                        params
                    } ).then ( response => {
                        // JSON responses are automatically parsed.
                        this.$log.debug ( "Users response: " , response.data );
                        this.users = response.data.content;
                        this.totalUsers = response.data.totalElements;
                        this.totalPages = response.data.totalPages;
                        this.loading = false;
                        let title = this.totalUsers;
                        this.$store.dispatch ( SET_BREADCRUMB , [{ title : `${this.name}: ` + title }] );
                        resolve ();
                    } );
                } );
            }
        }
    };


</script>
<style>
    .v-data-table.v-data-table.v-data-table >>> td {
        font-size: 16px;
    }

    .v-icon.v-icon {
        font-size: 24px !important;
    }

    tbody tr:nth-of-type(even) {
        background-color: rgba(236, 237, 237);
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgb(250, 250, 250);
    }

    tbody tr {
        width: 100%;
    }

    .filter {
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: center;
        background-color: aliceblue;
        border: 1px solid brown;
    }

    .search {
        display: flex;
        justify-content: space-around;
        padding: 20px;
    }

    .search-item {
        justify-content: center;
        display: flex;
    }

    .table-footer-prepend {
        margin-top: -58px;
        height: 58px;
    }
</style>
